export default {
  name: `Posichain`,
  node_halted_timeout: 120000,
  block_timeout: 10000,
  default_gas_price: 5e-9, // 5 Gwei

  // Ledger
  CosmosAppTestModeAllowed: false,
  mobileApp: Boolean(process.env.MOBILE_APP),

  e2e: process.env.VUE_APP_E2E
}
