/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'profile': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="1" d="M7.458 12.375a.23.23 0 00-.208-.125h-1A3.254 3.254 0 003 15.5v1.458c0 .25.208.5.5.5h5.375c.125 0 .25-.125.208-.291l-1.625-4.792zm6.75-.167h-1a.23.23 0 00-.208.125l-1.542 4.792c-.042.125.042.292.208.292H17c.25 0 .5-.209.5-.5v-1.459c-.042-1.791-1.5-3.25-3.292-3.25zm0-5.208a4.001 4.001 0 00-8 0 4.001 4.001 0 008 0zM10.208 12.333a.64.64 0 00-.625.625v3.334a.64.64 0 00.625.625.64.64 0 00.625-.625v-3.334a.64.64 0 00-.625-.625z" _fill="#767988"/>'
  }
})
