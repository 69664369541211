/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'details': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M60.533 28l-3.867-3.867a.645.645 0 00-.933 0l-2 2a.644.644 0 000 .934l3.867 3.866a.645.645 0 00.933 0l2-2a.644.644 0 000-.933zM28 46.267H8c-1.067 0-2 .933-2 2 0 1.066.933 2 2 2h20c1.066 0 2-.934 2-2 0-1.067-.934-2-2-2zM55.334 33.333l-3.867-3.866a.644.644 0 00-.933 0L39.867 40.133a.644.644 0 000 .934l3.867 3.866a.644.644 0 00.933 0l10.667-10.666c.266-.267.266-.8 0-.934zm-2.8-15.6c0-1.466-1.2-2.666-2.667-2.666H7.201a2.674 2.674 0 00-2.667 2.666V40.4c0 1.467 1.2 2.667 2.667 2.667h25.066c.133-.267 20.267-20.4 20.267-20.4v-4.934zM14.667 26.8L18 28.533c1.867.934 2.8 2.934 2.534 4.934-.267 1.733-1.333 3.066-2.933 3.6v.666c0 .934-.8 1.734-1.734 1.734-.933 0-1.733-.8-1.733-1.734V37.2c-1.6-.4-2.934-1.6-3.734-3.2-.4-.8 0-1.867.8-2.133.8-.4 1.867 0 2.134.8.4.933 1.333 1.466 2.4 1.466h.133c.8-.133.933-.8 1.067-1.066.133-.534-.134-1.067-.667-1.467l-3.333-1.733c-1.867-.934-2.8-2.934-2.4-5.067.4-1.867 1.733-3.333 3.6-3.733V20.4c0-.933.8-1.733 1.733-1.733s1.733.8 1.733 1.733v.933c1.334.534 2.4 1.6 2.934 2.934.4.8 0 1.866-.8 2.133-.8.4-1.867 0-2.134-.8-.4-.933-1.333-1.467-2.4-1.467-.933.134-1.066.8-1.2 1.067 0 .267-.133 1.067.667 1.6zm20 7.867h-8c-.933 0-1.733-.8-1.733-1.734 0-.933.8-1.733 1.733-1.733h8c.933 0 1.733.8 1.733 1.733-.133 1.067-.8 1.734-1.733 1.734zM40.001 28H26.667c-.933 0-1.733-.8-1.733-1.733 0-.934.8-1.734 1.733-1.734h13.334c.933 0 1.733.8 1.733 1.734C41.6 27.333 40.934 28 40 28zm-2.534 15.467a.644.644 0 00-.933 0L35.2 44.8c-.133.133-.133.267-.133.4l-.267 4.267c0 .4.267.8.8.666l4.134-.533c.133 0 .267-.133.4-.133l1.333-1.334a.644.644 0 000-.933l-4-3.733z" _fill="#D6D6D8"/>'
  }
})
