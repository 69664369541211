/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'network': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="1" d="M10.625 17.458l-1.583-2.875c-.083-.166-.25-.25-.458-.208-.209.042-.417.042-.625.042a2.935 2.935 0 01-1.584-.459.448.448 0 00-.583.125L3.75 17.375c-.333.5.042 1.208.667 1.208h5.458c.667.042 1.042-.625.75-1.125zM16.583 11.667c-.583-1-1.916-3-3.875-5l.625-.625c.5.166 1.083.083 1.5-.334.583-.583.583-1.5 0-2.041-.583-.584-1.5-.584-2.042 0-.416.416-.5 1-.333 1.5l-.667.625c-2-1.875-4-3.209-5-3.834-.374-.25-.874-.125-1.124.209-1.625 2.25-1.375 5.625.666 8.25-.166.291-.25.583-.25.916 0 1.042.834 1.875 1.875 1.875.417 0 .792-.166 1.125-.375 2.458 1.375 5.292 1.417 7.25 0 .417-.291.5-.791.25-1.166zM7.5 9.083c-.083.084-.208.084-.292.084a.576.576 0 01-.458-.25C5.875 7.5 5.583 5.875 6.042 4.5c.083-.292.375-.417.666-.333.292.083.417.375.334.666-.334 1.084-.125 2.417.583 3.542.167.25.125.583-.125.708z" _fill="#767988"/>'
  }
})
