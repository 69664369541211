/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'next_arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 13h14.17l-3.58 3.59L15 18l6-6-6-6-1.41 1.41L17.17 11H3v2z" _fill="#D6D6D8"/>'
  }
})
