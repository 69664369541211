/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sign': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" d="M23.067 13.733c-1.867-.8-2.8-1.6-3.6-3.6-.267-.533-.934-.533-1.2 0-.8 1.867-1.6 2.8-3.6 3.6-.534.267-.534.934 0 1.2 1.866.8 2.8 1.6 3.6 3.6.266.534.933.534 1.2 0 .8-1.866 1.6-2.8 3.6-3.6.533-.133.533-.933 0-1.2zM46.8 16.267c1.6.666 2.4 1.466 3.066 3.067.133.4.8.4 1.067 0 .666-1.6 1.466-2.4 3.066-3.067.4-.133.4-.8 0-1.067-1.6-.666-2.4-1.466-3.066-3.066-.134-.4-.8-.4-1.067 0-.667 1.6-1.467 2.4-3.067 3.066-.533.134-.533.8 0 1.067zM13.732 43.2c-1.6-.667-2.4-1.467-3.067-3.067-.133-.4-.8-.4-1.067 0-.666 1.6-1.466 2.4-3.066 3.067-.4.133-.4.8 0 1.067 1.6.666 2.4 1.466 3.066 3.066.134.4.8.4 1.067 0 .667-1.6 1.467-2.4 3.067-3.066.533-.267.533-.934 0-1.067zm43.066-17.333l-12.133-2.4c-.8-.133-1.467-.667-1.733-1.333l-6.4-11.067c-1.067-1.733-3.6-1.733-4.667 0l-6.4 11.067c-.4.666-1.067 1.066-1.733 1.333l-11.734 2.4c-2 .4-2.8 2.8-1.466 4.4l8.266 9.6c.534.533.667 1.333.667 2l-1.067 10.4c-.266 2.266 2.267 3.733 4.134 2.533.666-.4 1.066-1.2 1.066-2v-2.133c0-4.8 3.867-8.534 8.534-8.534h4.133c4.8 0 8.533 3.867 8.533 8.534V52.8c0 .8.4 1.6 1.067 2l.4.267c1.733 1.2 4.267-.133 4.133-2.267l-.266-9.866c0-.534.133-1.2.533-1.6l7.867-11.2c1.066-1.6.133-3.867-1.734-4.267zM34.133 38c-3.734 0-6.667-2.933-6.667-6.666 0-3.734 2.933-6.667 6.667-6.667 3.733 0 6.666 2.933 6.666 6.666 0 3.734-2.933 6.667-6.666 6.667z" _fill="#D6D6D8"/>'
  }
})
