/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'validators': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="1" d="M4.375 13.5c.916 0 1.666-.75 1.666-1.667 0-.916-.75-1.666-1.666-1.666-.917 0-1.667.75-1.667 1.666 0 .917.75 1.667 1.667 1.667zm10-1.667c0 .917.75 1.667 1.666 1.667.917 0 1.667-.75 1.667-1.667 0-.916-.75-1.666-1.667-1.666-.916-.042-1.666.708-1.666 1.666zm-.792.542A3.098 3.098 0 0113.625 11c.208-.667.708-1.167 1.333-1.458a2.45 2.45 0 011.5-.209c.125.042.25-.083.25-.208V3.458a.836.836 0 00-.833-.833H4.625a.836.836 0 00-.834.833v5.667c0 .125.125.208.25.208.542-.083 1.125 0 1.75.417.459.292.792.75.959 1.25.166.5.166.958.083 1.375-.042.125.083.25.208.25H7.5c.166 0 .25-.083.25-.167.25-1.083 1.25-1.916 2.458-1.916 1.167 0 2.167.833 2.458 1.916.042.084.084.167.209.167h.541c.125 0 .209-.125.167-.25zm-3.583-4H7.083a.552.552 0 01-.542-.542c0-.291.25-.541.542-.541H10c.291 0 .541.25.541.541 0 .292-.25.542-.541.542zM7.083 6.292a.552.552 0 01-.542-.542c0-.292.25-.542.542-.542h6.667c.291 0 .541.25.541.542 0 .292-.25.542-.541.542H7.083zm-1.042 8.041H2.708c-.75 0-1.375.625-1.375 1.375v.375c0 .25.167.417.417.417h4.458c.083 0 .167-.083.208-.167.125-.458.375-.833.75-1.125.084-.083.125-.208.042-.291a1.458 1.458 0 00-1.167-.584zm11.667 0h-3.333c-.5 0-.917.25-1.167.625a.257.257 0 00.042.292c.333.292.625.667.75 1.125.041.083.125.167.208.167h4.458c.25 0 .417-.167.417-.417v-.375c0-.792-.625-1.417-1.375-1.417z" _fill="#767988"/><path pid="2" d="M10.208 14.75c.917 0 1.667-.75 1.667-1.667 0-.916-.75-1.666-1.667-1.666s-1.667.75-1.667 1.666c0 .917.75 1.667 1.667 1.667zm1.667.833H8.54c-.75 0-1.375.625-1.375 1.375v.375c0 .25.167.417.417.417h5.25c.25 0 .417-.167.417-.417v-.375c0-.75-.625-1.375-1.375-1.375z" _fill="#767988"/>'
  }
})
