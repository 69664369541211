"use strict"

export default opts => ({
  // use Node
  blocks: require(`src/vuex/modules/blocks`).default(opts),
  transactions: require(`src/vuex/modules/transactions`).default(opts),
  distribution: require(`src/vuex/modules/distribution`).default(opts),
  delegates: require(`src/vuex/modules/delegates`).default(opts),
  delegation: require(`src/vuex/modules/delegation`).default(opts),
  connection: require(`src/vuex/modules/connection`).default(opts),
  proposals: require(`src/vuex/modules/governance/proposals`).default(opts),
  votes: require(`src/vuex/modules/governance/votes`).default(opts),
  deposits: require(`src/vuex/modules/governance/deposits`).default(opts),
  governanceParameters: require(`src/vuex/modules/governance/parameters`).default(opts),
  send: require(`src/vuex/modules/send`).default(opts),
  wallet: require(`src/vuex/modules/wallet`).default(opts),
  stakingParameters: require(`src/vuex/modules/parameters`).default(opts),
  pool: require(`src/vuex/modules/pool`).default(opts),
  minting: require(`src/vuex/modules/minting`).default(opts),

  // not use Node
  extension: require(`src/vuex/modules/extension`).default(opts),
  session: require(`src/vuex/modules/session`).default(opts),
  keystore: require(`src/vuex/modules/keystore`).default(opts),
  notifications: require(`src/vuex/modules/notifications`).default(opts),
  validators: require(`src/vuex/modules/validators`).default(opts),
})
